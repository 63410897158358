.initials-wrapper {
    height: 100px;
    width: 100px;
}

.initials {
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    margin: 0;
}

.innerSection,
.btns {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.uploadLabel {
    height: 42px;
    width: 173px;
    border-radius: 6px;
    background: #1e223e;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

input.hidden {
    display: none;
}

label:hover {
    cursor: pointer;
}

.picture {
    display: flex;
    margin-bottom: 2em;
    flex-direction: column;
    max-width: 588px;
    margin-inline: auto;
}

.helper {
    display: block;
    font-size: 12px;
}

.error {
    width: 100%;
    margin-top: 0;
    font-size: 80%;
    color: rgb(220, 53, 69)
}
