.instagram-slider__nav {
    margin-top: 40px
}

@media only screen and (min-width:768px) {
    .instagram-slider__nav {
        padding-bottom: 10px;
        margin: 0
    }
}

.instagram-slider__item {
    position: relative;
    vertical-align: top;
    background: rgba(53, 57, 118, .3);
    -webkit-transition: background .3s;
    -o-transition: background .3s;
    transition: background .3s
}

.instagram-slider__item:hover {
    background: 0 0
}

.instagram-slider__item::before {
    content: "";
    display: block;
    padding-top: 83.96226%
}

.instagram-slider__item .fa {
    color: #fff;
    font-size: 18px
}

.instagram-slider__item .instagram-slider__icon {
    position: absolute;
    left: 15px;
    bottom: 15px;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s
}

.instagram-slider__item:hover .instagram-slider__icon {
    opacity: 0
}

.instagram-slider__icon-hover {
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.instagram-slider__icon-hover .fa {
    font-size: 24px
}

.instagram-slider__item:hover .instagram-slider__icon-hover {
    opacity: 1
}

.instagram-slider__nav .slider__next, .instagram-slider__nav .slider__prev {
    color: #201f1f
}

.instagram-slider__nav .slider__next:hover, .instagram-slider__nav .slider__prev:hover {
    color: #fff;
    background: #201f1f;
    border-color: #201f1f
}
