/** MILMAP CSS **/
:root {
	--mm-red: #ec0407;
	--mm-red-2: #d81a1f;
	--mm-red-2: #d81a1f;
	--mm-red-gradient: linear-gradient(to top, #EC0407 50%, #D81A1F 50%);
	--mm-black: #1C1C1C;
	--mm-black-bg: #000000;
	--mm-red-rgb: 236, 4, 7;
	--mm-red-2-rgb: 216, 26, 31;
	--mm-black-rgb: 28, 28, 28;
	--mm-black-bg-rgb: 0, 0, 0;


	--mm-font-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--mm-title-font: "Auxilia", "Playfair Display", sans-serif;
	--mm-body-font: "Nunito", Merriweather Sans, Noto Sans, sans-serif;

}

body,
#mainNav .navbar-nav .nav-item .nav-link,
.btn {
	font-family: var(--mm-body-font);
}


::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 255, 0.3);
}

h1, h2, h3, h4, h5, h6 {
	font-family: var(--mm-title-font);
}

h1, h2 {
	font-weight: 500;
}

h3, h4 {
	font-weight: 400;
}

h5, h6 {
	font-weight: 300;
}

.flex {
	display: flex;
}

header.masthead {
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(28, 28, 28, 0.8) 100%), url('../../../public/img/milmap-home-hero-bg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
	overflow: hidden;
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 103vh;
}

header.masthead .container {
	width: 100% !important;
	margin: -75px 0 0 0 !important;
	padding: 0 !important;
	max-width: 100%;
	min-height: 103vh;
	overflow: hidden;
}

header.masthead .container video {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 0;
	min-width: 100%;
	min-height: 100%;
}

header.masthead .container .overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 0;
	min-width: 100%;
	min-height: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.27) 0%, rgba(28, 28, 28, 0.68) 100%);
}


header.masthead .hero-row {
	position: relative;
	width: 100%;
	padding: 220px 8% 100px !important;
	margin: -9px 0 0 0 !important;
	min-height: 106vh !important;
}

header.banner {
	height: 50vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
}

.page-section {
	padding: 6rem 0;
}

.donation-page {
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center center;
}

.donation-page-overlay {
	/* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.27) 0%, rgba(28, 28, 28, 0.68) 100%); */
	min-width: 100%;
	min-height: 100%;
	padding-top: 120px;
}

.donation-content {
	background: linear-gradient(138.4deg, rgba(255, 255, 255, 0.4) 11.73%, rgba(217, 217, 217, 0.1) 77.38%);
	box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(20px);
	padding: 20px 25px;
	border-radius: 10px;
}

.section-cta {
	width: 100%;
	position: relative;
	min-height: 480px;
	background: #f6f6f6;
	display: flex;
}

.mm-red {
	color: var(--mm-red);
}

.mm-red-2 {
	color: var(--mm-red-2);
}

.mm-red-bg {
	background-color: var(--mm-red);
}

.mm-red-2-bg {
	background-color: var(--mm-red-2);
}

.navbar-brand img {
	max-width: 100%;
	width: 160px;
}

.navbar-brand img.alt-logo {
	display: none;
}

.navbar-shrink .navbar-brand img.main-logo {
	display: none;
}

.navbar-shrink .navbar-brand img.alt-logo {
	display: block;
}

.nav-btn-wrap {
	display: inline-block;
	position: relative;
	margin: 0 0 0 40px;
}


.navbar-collapse.collapse.show .nav-btn-wrap {
	margin: 0;
}


.nav-btn-wrap .nav-btn {
	box-shadow: none;
	background: var(--mm-red);
	color: #ffffff;
	font-weight: 600;
	padding: 7px 24px;
	letter-spacing: -0.3px;
	transition: all ease .6s;
}

.btn-slide:hover, .btn-slide:focus {
	box-shadow: inset 10em 0 0 0 var(--mm-black);
	background: transparent;
	transition: all ease .8s;
}

.btn-hero {
	box-shadow: none;
	position: relative;
	display: inline-block;
	padding: 12px 25px 12px 60px;
	color: #ffffff;
	border: 1px solid transparent;
	background: transparent;
	transition: all ease .5s;
	border-radius: 25px;
	font-weight: 600;
}

.btn-hero:hover {
	box-shadow: inset 14em 0 0 0 #ffffff;
	background: #ffffff;
	transition: all ease .6s;
	color: var(--mm-red);
}

.btn-hero:before {
	content: "\f285";
	font-family: "bootstrap-icons";
	font-size: 20px;
	color: var(--mm-black);
	position: absolute;
	left: -2px;
	top: -1px;
	line-height: 50px;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background: #ffffff;
	transition: all ease .5s;
}

.btn-hero:hover:before {
	color: var(--mm-red);
	left: 3px;
	background: #ffffff;
	transition: all ease .5s;
}

.btn-hero:hover {
	border-color: #ffffff;
	position: relative;
	display: inline-block;
	padding: 12px 25px 12px 60px;
	color: var(--mm-red);
	background: transparent;
	transition: all ease .5s;
}

.btn-hero:after {
	visibility: hidden;
	content: "";
	width: 20px;
	height: 2px;
	background: #ffffff;
	position: absolute;
	left: 0;
	top: 23px;
	transition: all ease .3s;
}

.btn-hero:hover:after {
	content: "";
	width: 20px;
	height: 2px;
	background: var(--mm-red);
	position: absolute;
	left: 11px;
	top: 23px;
	visibility: visible;
	transition: all ease .3s;
}

.btn-hero-alt {
	box-shadow: none;
	position: relative;
	display: inline-block;
	padding: 12px 25px 12px 60px;
	color: var(--mm-red);
	border: 1px solid transparent;
	background: transparent;
	transition: all ease .5s;
	border-radius: 25px;
	font-weight: 600;
}

.btn-hero-alt:hover {
	box-shadow: inset 14em 0 0 0 var(--mm-red);
	background: var(--mm-red);
	transition: all ease .6s;
	color: #ffffff;
}

.btn-hero-alt:before {
	content: "\f285";
	font-family: "bootstrap-icons";
	font-size: 20px;
	color: #ffffff;
	position: absolute;
	left: -2px;
	top: -1px;
	line-height: 50px;
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background: var(--mm-red);
	transition: all ease .5s;
}

.btn-hero-alt:hover:before {
	color: #ffffff;
	left: 3px;
	background: var(--mm-red);
	transition: all ease .5s;
}

.btn-hero-alt:hover {
	border-color: transparent;
	position: relative;
	display: inline-block;
	padding: 12px 25px 12px 60px;
	color: #ffffff;
	background: transparent;
	transition: all ease .5s;
}

.btn-hero-alt:after {
	visibility: hidden;
	content: "";
	width: 20px;
	height: 2px;
	background: #ffffff;
	position: absolute;
	left: 0;
	top: 23px;
	transition: all ease .3s;
}

.btn-hero-alt:hover:after {
	content: "";
	width: 20px;
	height: 2px;
	background: #ffffff;
	position: absolute;
	left: 11px;
	top: 23px;
	visibility: visible;
	transition: all ease .3s;
}

.navbar-brand {
	padding-top: 0;
	padding-bottom: 0;
	margin-top: -5px;
}

hr.divider {
	height: 0.1rem;
	max-width: 5rem;
	background-color: var(--mm-red);
}

hr.left {
	margin: 1.5rem 0;
}

hr.divider-alt {
	height: 0.1rem;
	max-width: 5rem;
	background-color: #ffffff;
}

.section-title {
	margin-bottom: 40px;
}

.section-title .subtitle {
	display: block;
	font-size: 16px;
	color: #313131;
	font-family: var(--mm-body-font);
	letter-spacing: 0.2px;
	line-height: 26px;
	margin: 25px 0 20px;
}

.bg-grey {
	background: #f6f6f6;
}

.bg-grey-2 {
	background: #fbfcfd;
}

.bg-red {
	background: var(--mm-red-2);
}

.bg-light-green {
	background-color: rgb(34 197 94);
}

.post {
	transition: all ease .3s;
}

.post:hover {
	transition: all ease .3s;
	margin-top: -15px;
}

.post-item {
	box-shadow: 0 0 16px 0px rgb(45 45 45 / 9%);
	border-radius: 10px 10px 0 0;
	overflow: hidden;
	transition: all ease .3s;
}

.post-item:hover {
	box-shadow: 0 0 36px 0px rgb(45 45 45 / 15%);
	transition: all ease .3s;
}


.post-image .post-img-name {
	height: 175px;
	padding: 10px;
	background: rgb(46, 46, 46);
	display: flex;
	justify-content: center;
	align-items: center;
}

.post-image {
	width: 100%;
	overflow: hidden;
	margin: 0;
	height: 200px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.post-image figure {
	margin: 0;
	position: relative;
	text-align: center;
}

.post-image figure img {
	/* width: 100%; */
	max-width: 100%;
	object-fit: cover;
	transition: all ease .3s;
}

.post-content {
	padding: 30px 20px;
	background: #feffff;
	border: 1px solid #f2f3f4;
	margin-top: -10px;
	border-top: 0;
}

.post-content h3 {
	font-size: 1.35rem;
}

.post a {
	color: #1c1c1c;
	text-decoration: none;
	transition: all ease .3s;
}

.post a:hover, .post:hover a, .post:hover a:hover {
	color: var(--mm-red);
	text-decoration: none;
	transition: all ease .3s;
}

.section-cta .cols-1 {
	min-height: 480px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.section-cta>div {
	display: inline-block;
}

.section-cta .info-wrap .title-wrap {
	display: inline-block;
	width: 100%;
	padding: 60px 60px 10px;
}

.section-cta .info-wrap .title-wrap h1 {
	letter-spacing: 6px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 50px;
}

.section-cta .info-wrap .title-wrap h1 span {
	letter-spacing: 0.2px;
	font-weight: 500;
	display: block;
	font-size: 30px;
	margin: 25px 0 40px;
	text-transform: none;
}

.section-cta .prose-wrap {
	background: #b23f29;
	padding: 30px 60px;
}

.section-cta .prose-wrap p {
	font-size: 18px;
}

.section-cta .action-wrap {
	padding: 50px 60px;
}

/**interests **/
.interest {
	position: relative;
}

.interest .hover-canvas {
	z-index: -1;
	visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	padding: 0;
	width: 200%;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 0 30px 1px rgb(21 21 21 / 15%);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	transition: all ease-in .3s;
	display: none;
}

.interest:hover .hover-canvas {
	z-index: 1;
	visibility: visible;
	top: 0;
	display: block;
	transition: all ease-in .3s;
}

.interest:nth-child(1) .hover-canvas {
	left: -10%;
}

.interest:nth-child(2) .hover-canvas {
	left: -33%;
}

.interest:nth-child(3) .hover-canvas {
	left: -33%;
}

.interest:nth-child(4) .hover-canvas {
	left: -75%;
}

.interest .interest-wrap {
	background: #ffffff;
	padding: 25px 15px;
	border-radius: 5px;
	box-shadow: 0 0 15px rgb(0 0 0 / 5%);
}

.interest .hover-canvas .hover-content {
	display: inline-block;
	position: relative;
	padding: 35px;
	min-height: 360px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.5) 40%, rgba(0, 0, 0, 0.9) 100%);
}

.interest .hover-canvas .hover-content .interest-title {
	font-size: 30px;
	margin-bottom: 30px;
}

.interest .hover-canvas .hover-content .description p {
	font-size: 15px;
}

.fs-0 {
	font-size: 3.5rem !important;
}

/** Donors - 12 Star Generals **/
.donor {
	position: relative;
	display: inline-block;
	width: 100%;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
	border-radius: 6px;
	transition: all ease-in .3s;
}

.donor:hover {
	box-shadow: 0 -3px 25px 0 rgb(0 0 0 / 13%);
	margin-top: -10px;
}

.donor figure {
	position: relative;
	overflow: hidden !important;
	margin: 0;
	transition: all ease-in .3s;
}

.donor figure img {
	width: 100%;
	filter: grayscale(75%);
	transition: all ease-in .3s;
}

.donor:hover figure img {
	filter: grayscale(0%);
	transition: all ease-in .3s;
}

.donor figure figcaption {
	padding: 25px 15px 10px;
	transition: all ease-in .3s;
}

.donor figure figcaption h5 {
	margin: 0 0 10px;
	font-weight: 600;
	font-size: 19px;
	transition: all ease-in .3s;
}

.cta-wrap {
	background: #1c1c1c;
	padding: 70px 0;
	margin: 80px 0 0 0;
	width: 100%;
	position: relative;
}

.cta-wrap .cta-content {
	padding: 0 10%;
}

.cta-wrap .cta-content h4 {
	margin-bottom: 45px;
	font-size: 32px;
	line-height: 40px;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.btn-primary {
	color: #fff;
	background-color: var(--mm-red);
	border-color: var(--mm-red);
}

.btn-primary:hover {
	color: #fff;
	background-color: var(--mm-red-2);
	border-color: var(--mm-red-2);
}

/** Hero Tab Forms **/
.hero-tab .tab-1-nav button {
	border-radius: 4px 0 0 4px !important;
}

.hero-tab .tab-2-nav button {
	border-radius: 0 4px 4px 0 !important;
}

.hero-tab .nav-pills .nav-link {
	background: #efefef;
	font-weight: 600;
}

.hero-tab .nav-pills .nav-link.active {
	background: var(--mm-red);
}

.hero-tab .tab-content>.tab-pane {
	background: #fefefe;
	padding: 0;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 0 2px 1px rgb(0 0 0 / 76%);
}

.hero-tab .tab-content .wrap-header {
	padding: 10px 15px;
	background: #e4e4e4;
	margin: 0 0 15px;
	text-align: center;
	overflow: hidden;
	border-bottom: 1px solid #e0e0e0;
}

.hero-tab .tab-content .wrap-header h4 {
	font-size: 17px;
	line-height: 21px;
	font-weight: 600;
	margin: 0;
}

.hero-tab .tab-content .wrap-body {
	padding: 15px 15px 20px;
}

.hero-tab .ht-form input[type="radio"] {
	appearance: none;
	-webkit-appearance: none;
	width: 100%;
	height: 40px;
	position: relative;
	background: rgb(0 0 0 / 6%);
	border: 1px solid #e0e0e0;
	border-radius: 3px;
	cursor: pointer;
	z-index: 2;
	transition: all ease-in .3s;
}

.hero-tab .ht-form .radio-group .item label {
	position: absolute;
	left: 33%;
	top: 10px;
	z-index: 1;
	font-size: 15px;
	font-weight: 600;
	transform: translateX(-20%);
	transition: all ease-in .3s;
}

.hero-tab .ht-form .radio-group .item.monthly label {
	font-size: 15px;
	left: 15%;
	transform: translateX(15%);
}

.hero-tab .ht-form .radio-group .item label span {
	font-size: 11px;
	margin-left: 2px;
}

.hero-tab .ht-form input[type="radio"]:checked,
.hero-tab .ht-form input[type="radio"]:hover {
	background: var(--mm-red);
	border-color: var(--mm-red-2);
	transition: all ease-in .3s;
}

.hero-tab .ht-form input[type="radio"]:checked+label,
.hero-tab .ht-form input[type="radio"]:hover+label {
	color: #ffffff;
	z-index: 3;
	cursor: pointer;
	transition: all ease-in .3s;
}

.hero-tab .ht-form input[type="radio"]+label.other input {
	border-color: var(--mm-red-2);
	min-width: 100%;
	width: calc(100% + 168px);
	padding: 0 15px;
	position: absolute;
	display: inline-block;
	height: 40px;
	top: -10px;
	left: -157%;
	background: #eeeeee;
	border-radius: 2px;
	z-index: -1;
	opacity: 0;
	transition: all ease-in .1s;
}

.hero-tab .ht-form .radio-group .item.monthly input[type="radio"]+label.other input {
	left: -57%;
}

.hero-tab .ht-form input[type="radio"]:checked+label.other input {
	background: #ffffff;
	border-color: var(--mm-black);
	border-radius: 4px;
	outline: 0;
	box-shadow: none;
	z-index: 3;
	opacity: 1;
	transition: all ease-in .1s;
}

.hero-tab .ht-form .item {
	display: inline-block;
	position: relative;
	margin-bottom: 10px;
	padding-left: 10px;
	padding-right: 7px;
}

.hero-tab .ht-form .btn-wrap button {
	background: var(--mm-red);
	border-color: var(--mm-red);
	border-width: 1px;
	border-radius: 4px;
	width: 100%;
	padding: 10px 0;
	text-align: center;
	color: #ffffff;
	font-weight: 600;
}

/** Footer **/
footer {
	padding: 5rem 0 0 0;
}

footer .widget .title {
	margin-bottom: 40px;
}

footer .widget .title h4 {
	font-weight: 500;
	font-size: 21px;
}

footer a {
	color: #cfcfcf;
	text-decoration: none;
	transition: all ease-in .3s;
}

footer a:hover {
	color: var(--mm-red);
	text-decoration: none;
	transition: all ease-in .3s;
}

footer .text-widget p, footer a, footer li {
	font-size: 15px;
}

footer .social-icons .item i {
	margin-right: 15px;
	transition: all ease-in .3s;
}

footer .social-icons .item a:hover i,
footer .social-icons .item a:hover {
	color: #ffffff;
	transition: all ease-in .3s;
}

footer .copyright {
	border-top: 1px solid rgb(255 255 255 / 30%);
	padding: 20px;
	margin-top: 35px;
	color: #afafaf;
	transition: all ease-in .3s;
}

footer .copyright span {
	color: #fcfcfc;
	transition: all ease-in .3s;
}

footer .copyright span:hover {
	color: var(--mm-red);
	transition: all ease-in .3s;
}


@media (max-width: 991px) {
	.navbar-brand img.main-logo {
		display: none;
	}

	.navbar-brand img.alt-logo {
		display: block !important;
	}

	.interest .hover-canvas {
		width: 100%;
	}

	.interest .hover-canvas {
		left: 0 !important;
	}

	.donor {
		margin-bottom: 30px;
	}

	.navbar-light .navbar-toggler {
		border: none;
		box-shadow: none !important;
	}

	.post {
		margin-bottom: 30px;
	}

	footer .widget {
		margin-bottom: 45px;
	}
}

@media (max-width: 767px) {
	header.masthead {
		height: 100%;
		padding-bottom: 0;
	}

	header.masthead .container {
		width: 100% !important;
		margin: -100px 0 0 0 !important;
	}

	header.masthead .hero-row {
		padding: 100px 7.5% 100px !important;
	}

	header.masthead .container video {
		left: -53%
	}
}

@media (max-width: 600px) {
	header.masthead .hero-row {
		padding: 40px 5% 100px !important;
	}

	header.masthead .container video {
		left: -100%
	}
}

.message-box {
	padding: 15px 15px;
	margin-top: 20px;
	margin-bottom: 20px;
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 10px;
}

.message__author {
	font-weight: 600;
}

.messages__heading {
	padding: 20px 50px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.message__top {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.growth {
	height: calc(100vh - 230px);
}

.messages-list {
	height: calc(100vh - 230px);
	padding: 0 50px;
	margin-bottom: 0;
	overflow: scroll;
	list-style: none;
}

.messages-list::-webkit-scrollbar {
	display: none;
}

.new-message {
	position: relative;
	height: 70px;
	margin-right: 50px;
	margin-left: 50px;
}

.new-message__input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 8px;
	padding-right: 160px;
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 6px;
}

.new-message__button {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 0 16px;
	background-color: var(--mm-red);
	border: none;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	cursor: pointer;
}

.new-message__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
}

.new-message__button:hover {
    color: #000;
    background-color: var(--mono);
    border: none;
    font-weight: bold;
}

@media (max-width: 576px) {
	.new-message__input {
		padding-right: 50px;
	}
}

.empty-card {
	width: 50vw;
}

@media screen and(max-width: 768px) {

	.empty-card {
		width: 90vw;
	}
}

.empty-icon {
	width: 100px;
	height: 100px;
}

.or-seperator {
	margin: 20px 0 10px;
	text-align: center;
	border-top: 1px solid #ccc;
}

.or-seperator i {
	padding: 0 10px;
	background: #ffffff;
	position: relative;
	top: -11px;
	z-index: 1;
}

.social-btn .btn {
	font-size: 15px;
	line-height: 24px;
}

.social-btn .btn i {
	min-width: 15px;
}

.blog-post {
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 20px;
}

.blog-post__title {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 10px;
	text-align: center;
}

.blog-post__author {
	text-align: center;
	margin-top: -12px;
}

.blog-post__image {
	max-width: 100%;
	height: auto;
	max-height: 50vh;
	text-align: center;
	margin-bottom: 20px;
}

.blog-post__image--container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.blog-post__content {
	font-size: 16px;
	margin-bottom: 20px;
}

.blog-post__comments-toggle {
	background-color: #ccc;
	color: #fff;
	padding: 10px 20px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 16px;
}

.blog-post__comments-toggle:hover {
	background-color: #333;
}

.blog-post__comments {
	margin-top: 20px;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 20px;
}

.blog-post__comment {
	margin-bottom: 20px;
}

.blog-post__comment-text {
	font-size: 16px;
}

.cards-container {
	display: flex;
	flex-wrap: wrap;
	gap: 8px
}

.card-subcontainer {
	width: 31%;
	margin-bottom: 20px;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	overflow: hidden;
}

.card-image {
	height: 200px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f2f2f2;
}

.card-image img {
	max-width: 100%;
	max-height: 100%;
}

.card-content {
	padding: 20px;
}

.card-content h3 {
	margin: 0;
	font-size: 22px;
	font-weight: bold;
}

.card-content p {
	margin: 10px 0;
	font-size: 16px;
}

.donation-image {
	width: 100%;
	overflow: hidden;
	text-align: center;
	border-radius: 5px;
}

.donation-image img {
	width: 100%;
	object-fit: cover;
}


.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate-4-lines {
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.text-truncate {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}
.margin-left-50{
    margin-left:60%
}
.w-300{
    width: 250px;
}
.bg-lightgray{
    background: #6c757d;
}

.bg-lightBlue{
    background: #702917;
}

.b-rad5{
    border-radius: 10px;
}
.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

.phone-input{
	width: 100% !important;
  }
  