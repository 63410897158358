.wrapper {
    min-height: calc(100vh - 56px);
    overflow-y: auto;
}

.postList {
    list-style: none;
    width: 100%;
    padding-right: 2rem;
}

.postListItem {
    border-radius: 12px;
    min-height: 200px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 6px 12px -2px hsla(240, 30.1%, 28%, 0.25),
        0 4px 8px -4px hsla(0, 0%, 0%, 0.3), 0 -3px 8px -3px hsla(0, 0%, 0%, 0.03);
}

.error {
    width: 100%;
    margin-top: 0;
    font-size: 80%;
    color: #dc3545
}

.postListImage {
    height: 100%;
    min-height: 200px;
    max-width: 300px;
    display: flex;
    align-items: center;
    padding: 10px;
    overflow: hidden;
    flex-shrink: 0;
}

.postListImage img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    box-shadow: 0 6px 12px -2px hsla(240, 30.1%, 28%, 0.25),
        0 4px 8px -4px hsla(0, 0%, 0%, 0.3), 0 -3px 8px -3px hsla(0, 0%, 0%, 0.03);
}

.postListContent {
    padding: 10px 20px;
}

.postPreview {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.push_left {
    display: flex;
    justify-content: flex-end;
}


.push_left button {
    background: transparent;
}

.newPostForm {
    padding: .9rem 1.2rem;
    border-radius: 12px;
    box-shadow: 0 6px 12px -2px hsla(240, 30.1%, 28%, 0.25),
        0 4px 8px -4px hsla(0, 0%, 0%, 0.3), 0 -3px 8px -3px hsla(0, 0%, 0%, 0.03);
}

.preview {
    padding: 10px;
    position: relative;
}

.preview i {
    color: white;
    font-size: 35px;
    transform: translate(50px, 130px);
}

.preview-img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.browse-button {
    border-radius: 12px;
    position: absolute;
    /* Tweak the position property if the element seems to be unfit */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, transparent, black);
    opacity: 0;
    transition: 0.3s ease;
}

.browse-button:hover {
    opacity: 1;
}

.browse-input {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    transform: translate(-1px, -26px);
    opacity: 0;
}


@media screen and (max-width:600px) {
    .postListItem {
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 1rem;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width:767px) {
    .push_left {
        justify-content: flex-start;
    }
}
