/** Auxilia CSS **/
@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-ThinOblique.eot);
    src: local("Auxilia Thin Oblique"), local("Auxilia-ThinOblique"), url(../fonts/auxilia/Auxilia-ThinOblique.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-ThinOblique.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-ThinOblique.woff) format("woff"), url(../fonts/auxilia/Auxilia-ThinOblique.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-ThinOblique.svg#Auxilia-ThinOblique) format("svg");
    font-weight: 100;
    font-style: oblique;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-BoldOblique.eot);
    src: local("Auxilia Bold Oblique"), local("Auxilia-BoldOblique"), url(../fonts/auxilia/Auxilia-BoldOblique.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-BoldOblique.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-BoldOblique.woff) format("woff"), url(../fonts/auxilia/Auxilia-BoldOblique.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-BoldOblique.svg#Auxilia-BoldOblique) format("svg");
    font-weight: bold;
    font-style: oblique;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia.eot);
    src: local("Auxilia"), url(../fonts/auxilia/Auxilia.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia.woff2) format("woff2"), url(../fonts/auxilia/Auxilia.woff) format("woff"), url(../fonts/auxilia/Auxilia.ttf) format("truetype"), url(../fonts/auxilia/Auxilia.svg#Auxilia) format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-Light.eot);
    src: local("Auxilia Light"), local("Auxilia-Light"), url(../fonts/auxilia/Auxilia-Light.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-Light.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-Light.woff) format("woff"), url(../fonts/auxilia/Auxilia-Light.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-Light.svg#Auxilia-Light) format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-Black.eot);
    src: local("Auxilia Black"), local("Auxilia-Black"), url(../fonts/auxilia/Auxilia-Black.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-Black.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-Black.woff) format("woff"), url(../fonts/auxilia/Auxilia-Black.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-Black.svg#Auxilia-Black) format("svg");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-Bold.eot);
    src: local("Auxilia Bold"), local("Auxilia-Bold"), url(../fonts/auxilia/Auxilia-Bold.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-Bold.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-Bold.woff) format("woff"), url(../fonts/auxilia/Auxilia-Bold.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-Bold.svg#Auxilia-Bold) format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-BlackOblique.eot);
    src: local("Auxilia Black Oblique"), local("Auxilia-BlackOblique"), url(../fonts/auxilia/Auxilia-BlackOblique.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-BlackOblique.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-BlackOblique.woff) format("woff"), url(../fonts/auxilia/Auxilia-BlackOblique.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-BlackOblique.svg#Auxilia-BlackOblique) format("svg");
    font-weight: 900;
    font-style: oblique;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-Thin.eot);
    src: local("Auxilia Thin"), local("Auxilia-Thin"), url(../fonts/auxilia/Auxilia-Thin.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-Thin.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-Thin.woff) format("woff"), url(../fonts/auxilia/Auxilia-Thin.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-Thin.svg#Auxilia-Thin) format("svg");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-LightOblique.eot);
    src: local("Auxilia Light Oblique"), local("Auxilia-LightOblique"), url(../fonts/auxilia/Auxilia-LightOblique.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-LightOblique.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-LightOblique.woff) format("woff"), url(../fonts/auxilia/Auxilia-LightOblique.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-LightOblique.svg#Auxilia-LightOblique) format("svg");
    font-weight: 300;
    font-style: oblique;
}

@font-face {
    font-family: 'Auxilia';
    font-display: swap;
    src: url(../fonts/auxilia/Auxilia-Oblique.eot);
    src: local("Auxilia Oblique"), local("Auxilia-Oblique"), url(../fonts/auxilia/Auxilia-Oblique.eot#iefix) format("embedded-opentype"), url(../fonts/auxilia/Auxilia-Oblique.woff2) format("woff2"), url(../fonts/auxilia/Auxilia-Oblique.woff) format("woff"), url(../fonts/auxilia/Auxilia-Oblique.ttf) format("truetype"), url(../fonts/auxilia/Auxilia-Oblique.svg#Auxilia-Oblique) format("svg");
    font-weight: normal;
    font-style: oblique;
}
